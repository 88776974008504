<template>
  <v-container>
    <v-container class="text-h6">
      <v-row class="grey lighten-3 px-2 py-1">
        <IconText icon="fa-github" text="Github" url="https://github.com/evgo2017" iconSize="large"></IconText>
      </v-row>
    </v-container>
    <v-item-group>
      <v-container grid-list-lg class="px-0">
        <v-layout wrap>
          <v-flex
            v-for="(item, index) in $store.state.repo.items"
            :key="index"
            xs6
            sm4
            lg3>
            <v-hover>
              <v-item slot-scope="{ hover }" :class="`elevation-${hover ? 3 : 1}`">
                <RepoCard :item="item"></RepoCard>
              </v-item>
            </v-hover>
          </v-flex>
        </v-layout>
      </v-container>
    </v-item-group>
  </v-container>
</template>

<script>
import IconText from '@/components/base/IconText.vue'
import RepoCard from '@/components/Repo/RepoCard'

export default {
  name: 'Repo',
  components: {
    IconText,
    RepoCard
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
