<template>
  <v-card @click="$router.push({ name: item.routerName })">
    <v-img :src="`/imgs/repo/${item.imgName}`" Contain></v-img>
    <v-card-title class="pb-0 pt-2 text-truncate d-inline-block" style="max-width: 100%;">
      {{ item.name }}
    </v-card-title>
    <v-card-text class="py-0">
      <img v-if="item.repoName" :src="`https://img.shields.io/github/stars/evgo2017/${item.repoName}`" alt="">
      <img v-if="item.repoName" :src="`https://img.shields.io/github/forks/evgo2017/${item.repoName}`" alt="" class="mx-1">
      <img v-if="item.npmName" :src="`https://img.shields.io/npm/dt/${item.npmName}`" alt="">
    </v-card-text>
    <v-card-subtitle class="pt-1">
      {{ item.description }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'RepoCard',
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style scoped>
</style>
